.nav-icon {
    font-size: 1.5rem !important;
    color: white !important
}

.dropdown-toggle {
    color: white !important
}

.dropdown-toggle::after {
    color: #fff;
}

.dark-nav {
    background-color: #0d2f56 !important;
}

.dp-icon {
    font-size: 1.8rem !important;
}

@media screen and (max-width: 1400px) {
    .dropdown-menu {
        left: -80px !important;
    }
}