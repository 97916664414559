
.banner-text{
    display: flex;
    align-items: center;
    height: 100vh;
}

.banner-title {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.banner-color {
    color: #1cc7c1;
}
.banner-dscription{
    font-size: 17px;
    line-height: 1.71;
    color: #001439;
}

.main-button {
    appearance: none;
    border-radius: 16px;
    /* background-image: linear-gradient(135deg, #3191FF, #0d2f56); */
    background-color: #3191FF;
    color: #fff;
    padding: 16px 30px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    font-family: Poppins;
    outline: none;
    cursor: pointer;
    display: inline-block;
}

.main-button:hover {
    background-color: #0d2f56;
    color: #fff;
}

.login-btn{
    border-radius: 16px;
    color: #fff;
    background-color: #0d2f56;
    padding: 0.5rem 1.2rem;
}

.login-btn:hover{
    background-color: #3191FF;
    color: #fff;
}

.sidebar{
    background-image: url("../../assets/bg.jpg");
    background-position: center;
    background-size: cover;
    position: fixed;
    width: 32%;
    height: 100%;
}

@media only screen and (max-width: 1000px){
    .left-sidebar{
        display: none;
    } 
}
