.profile-section{
    /* background-color:red; */
    height: 100%;
}
.profile-title{
    padding: 20px 0px;
}
.profile-icon{
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 50%;
    color: #fff;
    background-color: lime;
    text-align: center;
}
.profile-icon i{
    font-size: 40px;
}
.personal-info table input{
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    border: 1px solid gray;
    border-radius: 10px;
}
.table-form{
    margin-bottom: 20px !important;
}

input:focus{
    outline: none;
}
.birth-date input{
    width: 70%;
}

.profile-card{
    border-radius: 16px!important;
    border: 1px dashed gray
}