.dashboard-header {
    background-color: #0d2f56;
    height: 90vh;
}

.dashboard-title {
    font-size: 2em;
    color: #fff;
    font-family: Poppins;
    margin-top: 60px;
}

.dashboard-subtitle {
    padding-right: 80px;
    font-size: 15px;
    color: #fff;
    font-family: Poppins;
}

.dashboard-card {
    border-radius: 16px !important;
}

.numbers .rect {

    /* z-index: 1; */
    background: white;
    width: 90%;
    height: 100%;
    padding-top: 3.5vmin;
    padding-bottom: 8.5vmin;
    margin: 1rem;
    border-radius: 1.5rem;
    box-shadow: 8px 8px 15px gray, -8px -8px 15px gray;
}

.numbers {
    margin-top: 15vmin;
}

.question-section::-webkit-scrollbar {
    display: none!important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.question-section {
    -ms-overflow-style: none!important;
    /* IE and Edge */
    scrollbar-width: none!important;
    /* Firefox */
}



.h-100 {
    height: 100vh !important;
}

.doc {
    border: 1px solid black !important;
    border-radius: 12px !important;
}

.card-footer {
    background: none !important;
    border-radius: 12px !important;
    border: none !important;
}

.card-bg {
    background: #e1f4fa !important;
}
.modal-container{
    position: relative;
}
.modal-show{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@media screen and (max-width: 575px){
    .dashboard-header {
        height: 110vh!important;
    }
}