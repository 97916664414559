.loan {
    border: none !important
}

.loan-body {
    background: #0d2f56 !important;
    color: white !important;
    border-radius: 16px !important;
}

.card-title {
    font-size: 18px;
    font-weight: 600;
}

.icon {
    font-size: 30px;

}

.progress {
    height: 5px;
}

.progress-bar {
    background-color: rgba(0, 128, 0, 0.603) !important;
}

.text-color {
    color: #3A4256;
}

/* .card-button-one {
    background-image: linear-gradient(135deg, #3191FF, #0d2f56);
    padding: 8px 20px;
    border-radius: 20%;
    color: #fff;
} */

.card-button-two {
    background: #3191FF;
    padding: 8px 20px;
    border-radius: 20%;
    color: #fff;
}

.card-button-two:hover{
    background: #0d2f56;
    color: white!important
}

.border-line {
    border-top: 1px solid lightgray;
    padding: 20px 0px;
}

a:link {
    text-decoration: none;
}

.loan-card-title {
    font-size: 17px;
    font-family: Poppins;
    font-weight: 700;
    color: white !important;
}