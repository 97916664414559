@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
body {
  overflow-x: hidden;
}
button:focus {
  outline: none;
  box-shadow: none;
}

.bg-cream {
  background-color: #f6f7fb;
}

.pointer{
  cursor: pointer;
}

.btn-secondary{
  border-radius: 16px!important;
}


::-webkit-scrollbar {
    width: 0px!important;
    background: transparent!important; /* make scrollbar transparent */
}

.bg-darkBlue{
  background-color: #001439
}
.bg-lightBlue{
  background-color:   #3191FF;
}
.bg-midBlue{
  background-color:   #0d2f56;
}

.text-darkBlue{
  color: #001439;
}
.text-lightBlue{
  color: #3191FF;
}
.text-midBlue{
  color:  #0d2f56;
}