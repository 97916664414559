.option {
  border-radius: 12px;
  box-shadow: 5px 5px 10px whitesmoke, -5px -5px 10px whitesmoke !important;
  transition: 0.5s;
}

.option:hover {
  cursor: pointer;
  background-color: #3191FF;
  box-shadow: 2px 2px 20px rgba(0, 20, 57, 0.5), -2px -2px 5px rgba(0, 20, 57, 0.6) !important;
}

.bg-blue{
  background-color: #3191FF;
}