@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow-x: hidden;
}
button:focus {
  outline: none;
  box-shadow: none;
}

.bg-cream {
  background-color: #f6f7fb;
}

.pointer{
  cursor: pointer;
}

.btn-secondary{
  border-radius: 16px!important;
}


::-webkit-scrollbar {
    width: 0px!important;
    background: transparent!important; /* make scrollbar transparent */
}

.bg-darkBlue{
  background-color: #001439
}
.bg-lightBlue{
  background-color:   #3191FF;
}
.bg-midBlue{
  background-color:   #0d2f56;
}

.text-darkBlue{
  color: #001439;
}
.text-lightBlue{
  color: #3191FF;
}
.text-midBlue{
  color:  #0d2f56;
}
.nav-icon {
    font-size: 1.5rem !important;
    color: white !important
}

.dropdown-toggle {
    color: white !important
}

.dropdown-toggle::after {
    color: #fff;
}

.dark-nav {
    background-color: #0d2f56 !important;
}

.dp-icon {
    font-size: 1.8rem !important;
}

@media screen and (max-width: 1400px) {
    .dropdown-menu {
        left: -80px !important;
    }
}
.loan {
    border: none !important
}

.loan-body {
    background: #0d2f56 !important;
    color: white !important;
    border-radius: 16px !important;
}

.card-title {
    font-size: 18px;
    font-weight: 600;
}

.icon {
    font-size: 30px;

}

.progress {
    height: 5px;
}

.progress-bar {
    background-color: rgba(0, 128, 0, 0.603) !important;
}

.text-color {
    color: #3A4256;
}

/* .card-button-one {
    background-image: linear-gradient(135deg, #3191FF, #0d2f56);
    padding: 8px 20px;
    border-radius: 20%;
    color: #fff;
} */

.card-button-two {
    background: #3191FF;
    padding: 8px 20px;
    border-radius: 20%;
    color: #fff;
}

.card-button-two:hover{
    background: #0d2f56;
    color: white!important
}

.border-line {
    border-top: 1px solid lightgray;
    padding: 20px 0px;
}

a:link {
    text-decoration: none;
}

.loan-card-title {
    font-size: 17px;
    font-family: Poppins;
    font-weight: 700;
    color: white !important;
}
.dashboard-header {
    background-color: #0d2f56;
    height: 90vh;
}

.dashboard-title {
    font-size: 2em;
    color: #fff;
    font-family: Poppins;
    margin-top: 60px;
}

.dashboard-subtitle {
    padding-right: 80px;
    font-size: 15px;
    color: #fff;
    font-family: Poppins;
}

.dashboard-card {
    border-radius: 16px !important;
}

.numbers .rect {

    /* z-index: 1; */
    background: white;
    width: 90%;
    height: 100%;
    padding-top: 3.5vmin;
    padding-bottom: 8.5vmin;
    margin: 1rem;
    border-radius: 1.5rem;
    box-shadow: 8px 8px 15px gray, -8px -8px 15px gray;
}

.numbers {
    margin-top: 15vmin;
}

.question-section::-webkit-scrollbar {
    display: none!important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.question-section {
    -ms-overflow-style: none!important;
    /* IE and Edge */
    scrollbar-width: none!important;
    /* Firefox */
}



.h-100 {
    height: 100vh !important;
}

.doc {
    border: 1px solid black !important;
    border-radius: 12px !important;
}

.card-footer {
    background: none !important;
    border-radius: 12px !important;
    border: none !important;
}

.card-bg {
    background: #e1f4fa !important;
}
.modal-container{
    position: relative;
}
.modal-show{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@media screen and (max-width: 575px){
    .dashboard-header {
        height: 110vh!important;
    }
}
.option {
  border-radius: 12px;
  box-shadow: 5px 5px 10px whitesmoke, -5px -5px 10px whitesmoke !important;
  transition: 0.5s;
}

.option:hover {
  cursor: pointer;
  background-color: #3191FF;
  box-shadow: 2px 2px 20px rgba(0, 20, 57, 0.5), -2px -2px 5px rgba(0, 20, 57, 0.6) !important;
}

.bg-blue{
  background-color: #3191FF;
}
.h-100 {
  min-height: 100vh !important;
}
.circle {
  border-radius: 50%;
  font-size: 0.8rem;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  background: rgb(224, 224, 224);
  display: flex;
  justify-content: center;
  align-items: center;
}

.question {
  margin: 0 10px;
  border-radius: 24px;
  background: white;
  box-shadow: 3px 3px 8px rgba(0, 20, 57, 0.4)!important;
  
}

.input-container {
  position: relative;
  border-radius: 16px;
  border: dashed 1px #aeb9c1;
  width: 60%;
  padding: 1.8rem;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-field {
  border-radius: 10px !important;
  padding: 2rem 1.5rem !important;
}

.input-icon {
  position: relative;
  font-size: 1.4rem !important;
}

.input-icon>i {
  position: absolute;
  display: block;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon>input {
  padding-left: 25px;
  padding-right: 0;
  font-size: 1.3rem !important;
}

.skip:hover {
  color: skyblue
}

.banner-text{
    display: flex;
    align-items: center;
    height: 100vh;
}

.banner-title {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.banner-color {
    color: #1cc7c1;
}
.banner-dscription{
    font-size: 17px;
    line-height: 1.71;
    color: #001439;
}

.main-button {
    -webkit-appearance: none;
            appearance: none;
    border-radius: 16px;
    /* background-image: linear-gradient(135deg, #3191FF, #0d2f56); */
    background-color: #3191FF;
    color: #fff;
    padding: 16px 30px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    font-family: Poppins;
    outline: none;
    cursor: pointer;
    display: inline-block;
}

.main-button:hover {
    background-color: #0d2f56;
    color: #fff;
}

.login-btn{
    border-radius: 16px;
    color: #fff;
    background-color: #0d2f56;
    padding: 0.5rem 1.2rem;
}

.login-btn:hover{
    background-color: #3191FF;
    color: #fff;
}

.sidebar{
    background-image: url(/static/media/bg.97a7b0f7.jpg);
    background-position: center;
    background-size: cover;
    position: fixed;
    width: 32%;
    height: 100%;
}

@media only screen and (max-width: 1000px){
    .left-sidebar{
        display: none;
    } 
}

.profile-section{
    /* background-color:red; */
    height: 100%;
}
.profile-title{
    padding: 20px 0px;
}
.profile-icon{
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 50%;
    color: #fff;
    background-color: lime;
    text-align: center;
}
.profile-icon i{
    font-size: 40px;
}
.personal-info table input{
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    border: 1px solid gray;
    border-radius: 10px;
}
.table-form{
    margin-bottom: 20px !important;
}

input:focus{
    outline: none;
}
.birth-date input{
    width: 70%;
}

.profile-card{
    border-radius: 16px!important;
    border: 1px dashed gray
}
.bg-img {
    background: #f6f7fb;
}
.w-35{
    width: 35%;
}
