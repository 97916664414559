.h-100 {
  min-height: 100vh !important;
}
.circle {
  border-radius: 50%;
  font-size: 0.8rem;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  background: rgb(224, 224, 224);
  display: flex;
  justify-content: center;
  align-items: center;
}

.question {
  margin: 0 10px;
  border-radius: 24px;
  background: white;
  box-shadow: 3px 3px 8px rgba(0, 20, 57, 0.4)!important;
  
}

.input-container {
  position: relative;
  border-radius: 16px;
  border: dashed 1px #aeb9c1;
  width: 60%;
  padding: 1.8rem;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-field {
  border-radius: 10px !important;
  padding: 2rem 1.5rem !important;
}

.input-icon {
  position: relative;
  font-size: 1.4rem !important;
}

.input-icon>i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon>input {
  padding-left: 25px;
  padding-right: 0;
  font-size: 1.3rem !important;
}

.skip:hover {
  color: skyblue
}